/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        //AJAX
        const $loadMoreBtn = $('.js-load-more');
        const $postsContainer = $('.js-loaded-posts');
        const $postsLoadedOnStart = $('.js-loaded-on-start-posts');
        const $loadMore = $('.js-load-more');
        const $loaderContainer = $('.js-loader-container');
        const $loadMoreContainer = $('.js-load-more-container');

        let xhr = null;
        const initial_offset = 9;

        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function(e) {
            e.preventDefault();
            
            abort(xhr);
            let offset = $postsContainer.find('.js-card-news').length ? initial_offset + $postsContainer.find('.js-card-news').length : initial_offset;
            let url = `/ajax/posts/${offset}/`;
            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {

                },
                beforeSend: () => {
                    TweenMax.to($loadMore, 0.5, {autoAlpha: 0, y:30, display: "none", ease: Power1.easeOut, onComplete: () => {
                        TweenMax.fromTo($loaderContainer, 0.5, {autoAlpha: 0, y:30, display: "none"}, {autoAlpha: 1, y:0, display: "flex", ease: Power1.easeOut})
                    }})
                },
                success: (response, status) => {
                    console.log(response)
                    $postsContainer.append(response);
                    TweenMax.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});
                    if($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    } else {
                        TweenMax.to($loadMore, 0.5, {autoAlpha: 1, y:0, display: "flex", ease: Power1.easeOut})
                    }
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
        });

        /*
        |
        | Filter ajax
        |--------------
        */


        $('.js-filter-element').on('click', (e) => {
            setTimeout(() => {
                filterAjax(e)
            }, 1)
        })

        const filterAjax = (e) => {
            abort(xhr);
            let url = `/ajax/filterposts/`;
            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    posts_type: $('.js-filter-element-type.selected').attr('data-value'),
                    posts_topic: $('.js-filter-element-topic.selected').attr('data-value'),
                },
                beforeSend: () => {
                    // $('body').addClass('no-scroll');
                    // $('.js-loader-search').addClass('active');
                },
                success: (response, status) => {
                    console.log(response)
                    // $('body').removeClass('no-scroll');
                    // $('.js-loader-search').removeClass('active');
                    $postsLoadedOnStart.empty();
                    $postsContainer.empty();
                    $postsContainer.append(response);
                    TweenMax.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});                    
                    if($('body').find('.load-more-post').length >= 1) {
                        $loadMoreContainer.show();
                    } else {
                        $loadMoreContainer.hide();
                    }
                    
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
