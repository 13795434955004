/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        $('.js-popup-download-form-on').on('click', () => {
            $('body').addClass('no-scroll')
            $('.js-popup-download-form').addClass('active')
        })

        $('.js-popup-download-form-off').on('click', () => {
            $('body').removeClass('no-scroll')
            $('.js-popup-download-form').removeClass('active')
        })

        const slug = $('.js-slug').attr('data-slug')

        if (sessionStorage.getItem(`form_${slug}`)) {
            $('body').addClass('no-scroll')
            $('.js-popup-download-form').addClass('active')
        }

        $('.js-submit-downloadform').on('click', (e) => {
            sessionStorage.setItem(`form_${slug}`, true);
            $('#gform_4').submit();
        })
    }
}
