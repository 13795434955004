/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        let step = 1;

        $('document').ready(() => {
            $('.js-page-two').addClass('hide')
            $('.js-page-three').addClass('hide')
            $('.js-page-four').addClass('hide')

            setTimeout(() => {
                setHeight()
            }, 300)
        })

        //Events

        $('.js-next').on('click', () => {
            if(step < 4) {
                step++
                updateInputs(step)
            }
        })

        $('.js-prev').on('click', () => {
            if(step > 1) {
                step--
                updateInputs(step)
            }
        })

        $('.js-step-element').on('click', (e) => {
            step = parseInt($(e.currentTarget).attr('data-index'))
            updateInputs(step)
        })

        $('.js-submit').on('click', (e) => {
            $('.js-submit').addClass('disabled')
            $('#gform_2').submit();
        })

        //Functions

        const setHeight = () => {
            const height = $('.js-form').height()
            $('.js-image').css('height', `${height}px`)
            $('.js-background').css('height', `${height}px`)
        }


        const updateInputs = (step) => {

            $('.js-step-element').removeClass('active')
            $(`.js-step-element[data-index="${step}"]`).addClass('active')
            $('.js-next-bottom').removeClass('hide')
            $('.js-submit').addClass('hide')

            switch(step) {
                case 1 :
                    $('.js-page-one').removeClass('hide')
                    $('.js-page-two').addClass('hide')
                    $('.js-page-three').addClass('hide')
                    $('.js-page-four').addClass('hide')
                    break;
                
                case 2 :
                    $('.js-page-one').addClass('hide')
                    $('.js-page-two').removeClass('hide')
                    $('.js-page-three').addClass('hide')
                    $('.js-page-four').addClass('hide')
                    break;
                
                case 3 :
                    $('.js-page-one').addClass('hide')
                    $('.js-page-two').addClass('hide')
                    $('.js-page-three').removeClass('hide')
                    $('.js-page-four').addClass('hide')
                    break;

                case 4 :
                    $('.js-page-one').addClass('hide')
                    $('.js-page-two').addClass('hide')
                    $('.js-page-three').addClass('hide')
                    $('.js-page-four').removeClass('hide')
                    $('.js-next-bottom').addClass('hide')
                    $('.js-submit').removeClass('hide')
                    break;
            }

            setHeight()
        }
        
    }
}
