/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        $( document ).ready(function() {
            const height = $('.js-manifesto').outerHeight() + 300

            $('.js-cover').css('height', `${height}px`)
        })
    }
}
