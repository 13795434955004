/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm, customGoogleMap) => {
        $('.js-submit').on('click', (e) => {
            $('#gform_3').submit();
        })
    }
}
