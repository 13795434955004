/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        //INIT

        let url = window.location.search;
        url = url.substring(1)
        url = url.split('&')

        console.log(url)

        window.addEventListener('DOMContentLoaded', () => {
            for (let i = 0; i < url.length; i++) {
                const query = url[i].split('=');

                console.log(query)

                switch (query[0]) {
                    case 'theme':
                        if (query[1]) {
                            console.log(query[1])
                            $(`.js-filter-element-themes[data-slug="${query[1]}"]`).click();
                        }
                        break;

                    default:
                        break;
                }
            }
        })


        //EVENTS
        $('.js-toggle-filter').on('click', () => {

            if($('.js-toggle-filter-element.active').length >= 1) {
                $('.js-toggle-filter-element').removeClass('active')
                $('body').removeClass('no-scroll')
            } else {
                $('.js-toggle-filter-element').addClass('active')
                $('body').addClass('no-scroll')
            }            

            const save = $('.js-toggle-filter-text').text()
            $('.js-toggle-filter-text').empty().append($('.js-toggle-filter-text').attr('data-text'))
            $('.js-toggle-filter-text').attr('data-text', save)
        })

        $('.js-filter-element').on('click', (e) => {
            const $el = $(e.currentTarget)
            $el.toggleClass('active')
        })

        $('.js-reset-filters').on('click', (e) => {
            $('.js-filter-element').removeClass('active')
            $('.js-filter-select[data-first]').click()
            status_choices = []
            themes_choices = []
            location_choices = []
            investor_choices = []
            filterAjax(e)
        })

        //AJAX
        const $loadMoreBtn = $('.js-load-more');
        const $postsContainer = $('.js-loaded-posts');
        const $postsLoadedOnStart = $('.js-loaded-on-start-posts');
        const $loadMore = $('.js-load-more');
        const $loaderContainer = $('.js-loader-container');
        const $loadMoreContainer = $('.js-load-more-container');

        let xhr = null;
        const initial_offset = 20;

        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function(e) {
            e.preventDefault();
            
            abort(xhr);
            let offset = $postsContainer.find('.js-card-startups').length ? initial_offset + $postsContainer.find('.js-card-startups').length : initial_offset;
            let url = `/ajax/startups/${offset}/`;
            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {

                },
                beforeSend: () => {
                    TweenMax.to($loadMore, 0.5, {autoAlpha: 0, y:30, display: "none", ease: Power1.easeOut, onComplete: () => {
                        TweenMax.fromTo($loaderContainer, 0.5, {autoAlpha: 0, y:30, display: "none"}, {autoAlpha: 1, y:0, display: "flex", ease: Power1.easeOut})
                    }})
                },
                success: (response, status) => {
                    console.log(response)
                    $postsContainer.append(response);
                    TweenMax.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});
                    if($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    } else {
                        TweenMax.to($loadMore, 0.5, {autoAlpha: 1, y:0, display: "flex", ease: Power1.easeOut})
                    }
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
        });

        /*
        |
        | Filter ajax
        |--------------
        */

        let status_choices = [];

        $('.js-filter-element-status').on('click', (e) => {
            
            // $('.js-filter-element-status').removeClass('active')
            // $(e.currentTarget).addClass('active')
            
            status_choices = [];
            setTimeout(() => {
                $('.js-filter-element-status.active').each(function() {
                    status_choices.push($(this).attr('data-slug'));
                });
                filterAjax(e)
            }, 1)
        })


        let themes_choices = [];

        $('.js-filter-element-themes').on('click', (e) => {
            themes_choices = [];
            setTimeout(() => {
                $('.js-filter-element-themes.active').each(function() {
                    themes_choices.push($(this).attr('data-slug'));
                });
                filterAjax(e)
            }, 1)
        })


        let location_choices = [];

        $('.js-filter-element-location').on('click', (e) => {
            location_choices = [];
            setTimeout(() => {
                $('.js-filter-element-location.active').each(function() {
                    location_choices.push($(this).attr('data-slug'));
                });
                filterAjax(e)
            }, 1)
        })


        let investor_choices = ''

        $('.js-filter-select').on('click', (e) => {
            setTimeout(() => {
                investor_choices = $('.js-filter-select.selected').attr('data-value');
                filterAjax(e)
            }, 1)
        })


        let letter_start = '';

        $('.js-letters').on('click', (e) => {
            
            $('.js-letters').removeClass('active')
            $(e.currentTarget).addClass('active')

            $(e.currentTarget).toggleClass('clicked')

            if($(e.currentTarget).hasClass('clicked')) {
                letter_start = $(e.currentTarget).attr('data-letter')
            } else {
                letter_start = ''
                $(e.currentTarget).removeClass('active')
            }

            setTimeout(() => {
                filterAjax(e)
            },1)
        })

        const filterAjax = (e) => {
            abort(xhr);
            let url = `/ajax/filterstartups/`;
            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    startups_status: status_choices,
                    startups_location: location_choices,
                    startups_theme: themes_choices,
                    startups_investor: investor_choices,
                    letter: letter_start,
                },
                beforeSend: () => {
                    // $('body').addClass('no-scroll');
                    $('.js-loader-search').addClass('active');
                },
                success: (response, status) => {
                    console.log(response)
                    // $('body').removeClass('no-scroll');
                    $('.js-loader-search').removeClass('active');
                    $postsLoadedOnStart.empty();
                    $postsContainer.empty();
                    $postsContainer.append(response);
                    TweenMax.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});                    
                    if($('body').find('.load-more-post').length >= 1) {
                        $loadMoreContainer.show();
                    } else {
                        $loadMoreContainer.hide();
                    }
                    
                    
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
