/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        // accordion
        $('.elementor-tab-title').on('click', function() {
            let $title = $(this),
                $tab = $title.parents('.elementor-accordion-item'),
                isOpen = $tab.hasClass('--open')

            $('.elementor-accordion-item.--open').toggleClass('--open', false)
            $tab.toggleClass('--open', !isOpen)
        })

        // faq
        $('.elementor-tab-title').on('click', function() {
            let $title = $(this),
                $tab = $title.parents('.elementor-toggle-item'),
                isOpen = $tab.hasClass('--open')

            $tab.toggleClass('--open', !isOpen)
        })

        // numbers
        let numbersObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                let target = entry.target,
                    toValue = target.dataset.toValue,
                    duration = target.dataset.duration

                if (entry.isIntersecting) {
                    let interval = setInterval(() => {
                        let current = parseInt(target.innerHTML)

                        if (current < toValue) {
                            target.innerHTML = current + 1
                        } else {
                            clearInterval(interval)
                        }
                    }, duration / toValue)
                }
            })
        })

        let numbers = document.querySelectorAll('.elementor-counter-number')

        numbers.forEach((number) => {
            numbersObserver.observe(number)
        })

        // alerts
        document.querySelectorAll('.elementor-widget-alert').forEach(function(alert) {
            let close = alert.querySelector('.elementor-alert-dismiss')

            close.addEventListener('click', function() {
                alert.style.display = 'none'
            })
        })
    }
}
