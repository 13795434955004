/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, ScrollMagic) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            $body         = $('body')
		;

        $body.on('loaderEnd', () => console.log('ended'))

        /*
		|
		| Header menu scroll
		|-----------------
        */
        let oldScrollY = 0

        const updateMenu = (oldScrollY) => {
            const currentScrollY = window.scrollY;
            const oldScrollYOffset = $('.header-container').outerHeight();
            if(currentScrollY < 10) {
                // $('#header').removeClass('translated');
                $('#header').removeClass('active');
            } else if(currentScrollY > oldScrollY) {
                // $('#header').addClass('translated');
                $('#header').addClass('active');
            } else {
                // $('#header').removeClass('translated');
                $('#header').addClass('active');
            }

            if(!currentScrollY) {
                $('.nav-drop').removeClass('top');
            }

            const elm = document.body
            const p = elm.parentNode
            const scroll_percentage = Math.round((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight ) * 100)

            if(scroll_percentage == 100) {
                console.log('scroll 100%')
            }

            if(scroll_percentage == 75) {
                console.log('scroll 75%')
            }

            if(scroll_percentage == 50) {
                console.log('scroll 50%')
            }

            if(scroll_percentage == 25) {
                console.log('scroll 25%')
            }

            return currentScrollY;
        }

        oldScrollY = updateMenu(oldScrollY);

        window.addEventListener('scroll', function(e) {
            oldScrollY = updateMenu(oldScrollY);
        })

         /*
		|
		| Nav drop
		|-----------------
		*/

        $('.js-nav-drop').on('mouseover', (e) => {
            const id = $(e.currentTarget).attr('data-index')
            $('.js-nav-drop-menu').removeClass('active')
            $(`.js-nav-drop-menu[data-index="${id}"]`).toggleClass('active')
        })

        $('.js-nav-drop-menu').on('mouseleave', (e) => {
            $('.js-nav-drop-menu').removeClass('active')
        })

        $('.js-navdrop-mobile-trigger').on('click', (e) => {
            const index = $(e.currentTarget).attr('data-index')
            $('.js-navdrop-mobile').removeClass('active')
            $(`.js-navdrop-mobile[data-index="${index}"]`).addClass('active')
        })


         /*
		|
		| Band
		|-----------------
		*/
        $('.js-headband-close').on('click', () => {
            $('.js-headband').addClass('hide')
        })



        /*
		|
		| Swiper
		|-----------------
		*/

        const swiperFamily = new Swiper('.js-swiper-family', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            breakpoints: {
                450: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4.2,
                }
            },
            navigation: {
                nextEl: '.js-swiper-button-next',
                prevEl: '.js-swiper-button-prev',
            },
        })

        $('.js-family-slide').on({
            'mouseover': (e) => {
                $(e.currentTarget).find('.js-family-slide-hover').addClass('active')
            },

            'mouseleave': (e) => {
                $(e.currentTarget).find('.js-family-slide-hover').removeClass('active')
            }
        })

        const swiperProgram = new Swiper('.js-swiper-program', {
            loop: true,
            slidesPerView: 'auto',
            spaceBetween: 40,
            navigation: {
                nextEl: '.js-swiper-button-next',
                prevEl: '.js-swiper-button-prev',
            },
        })

        /*
		|
		| Scrollmagic
		|-----------------
		*/

        const controller = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}});

        // -> home & scaling
        if($('.js-section-faq').length >= 1) {
            $(document).ready(function() {
	            new ScrollMagic.Scene({triggerElement: ".js-section-faq"})
	            				.setTween(".js-section-faq > .js-decoration-faq", {y: "-10%", ease: Linear.easeNone})
                                .addTo(controller);
            })
        }

        //-> home header
        if($('.home').length >= 1) {
            $(document).ready(function() {
	            new ScrollMagic.Scene({
                    reverse: true,
                })
	            				.setTween(".js-header-home > .js-header-home-up", {y: "-10%", ease: Power2.easeOut})
                                .addTo(controller);
            })
        }

        if($('.home').length >= 1) {
            $(document).ready(function() {
	            new ScrollMagic.Scene({
                    reverse: true,
                })
	            				.setTween(".js-header-home > .js-header-home-down", {y: "10%", ease: Power2.easeOut})
                                .addTo(controller);
            })
        }

        // -> newsletter
        if($('.js-section-newsletter').length >= 1) {
            $(document).ready(function() {
	            new ScrollMagic.Scene({triggerElement: ".js-section-newsletter"})
	            				.setTween(".js-section-newsletter > .js-decoration-newsletter", {y: "-60%", ease: Linear.easeNone})
                                .addTo(controller);
            })
        }

        /*
		|
		| FAQ
		|-----------------
		*/

        $('.js-titleContainer').on('click', (e) => {
			const id = $(e.currentTarget).attr('data-index');

            $('.js-faq-element').css('opacity', .5)
            $(e.currentTarget).parent().css('opacity', 1)

            if(!$(e.currentTarget).hasClass('active')) {

				$(`.js-textContainer`).removeClass('active');
                $(`.js-textContainer[data-index=${id}]`).addClass('active');

                $(`.js-titleContainer`).removeClass('active');
                $(`.js-titleContainer[data-index=${id}]`).addClass('active');

            } else {
				$(`.js-titleContainer`).removeClass('active');
				$(`.js-textContainer`).removeClass('active');
			}
        })

         /*
		|
		| Newsletter
		|-----------------
		*/

        $('.footer__newsletter').on('click', '.footer__newsletter__title, .footer__newsletter__toggle', (e) => {
            $('.footer__newsletter').toggleClass('--down')
        })

        $('.js-submit-newsletter').on('click', (e) => {
            $('#gform_1').submit();
        })

        $('.js-team-hover').on('click', (e) => {
            $(e.currentTarget).toggleClass('active');
        })

        /*
		|
		| Scroll to Anchor
		|-----------------
		*/


        $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
          // On-page links
          if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
          ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                };
              });
            }
          }
        });

        /*
		|
		| Select
		|-----------------
		*/

        for (const option of document.querySelectorAll(".custom-option")) {
            option.addEventListener('click', function() {
                console.log('looool')
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.custom-select').querySelector('.custom-select__trigger span').textContent = this.textContent;
                }
            })
        }

        for (const dropdown of document.querySelectorAll(".custom-select-wrapper")) {
            dropdown.addEventListener('click', function() {
                this.querySelector('.custom-select').classList.toggle('open');
            })
        }

        window.addEventListener('click', function(e) {
            for (const select of document.querySelectorAll('.custom-select')) {
                if (!select.contains(e.target)) {
                    select.classList.remove('open');
                }
            }
        });
	}
}
