/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        $('.js-card-passion').on('mouseover', (e) => {
            $('.js-card-passion').css('opacity', .5)
            $(e.currentTarget).css('opacity', 1)
        })

        $('.js-card-passion-container').on('mouseleave', (e) => {
            $('.js-card-passion').css('opacity', 1)
        })
    }
}
